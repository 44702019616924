import { Component, Input, OnInit, ContentChild} from '@angular/core';


@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.less']
})
export class TableComponent implements OnInit {

  pageSize = 15;
  showSizeChanger = false;
  pageSizeOptions = [5, 10, 20, 30, 40, 50, 100];

  listOfData: Array<any> = [];
  listOfDisplayData: Array<any> = [];

  @ContentChild('rowTemplate') tableRowTemplate;
  @Input() tableHeaderData: any = {}
  @Input() set getTableRowData(value) {
    this.listOfData = [
      ...value || []
    ];
    this.listOfDisplayData = [
      ...value || []
    ];
  }

  ngOnInit() {
  }
}
